@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

:root {
    --font-two: rgb(173, 0, 107);
    --font-one: black;
    --font-pop: 'Poppins', sans-serif;
    --font-code: 'Fira Code', monospace !important;
}

body {
    background: linear-gradient(45deg, rgb(110, 1, 68) 5%, rgb(19, 20, 31) 60%);
    background-color: rgb(19, 20, 31);
    font-family: 'Poppins', sans-serif;
    color: white;
    padding: 0;
    margin: 0;
}

h1 {
    padding-bottom: 25px;
    font-size: 35px;
}

h2 {
    font-size: 30px;
}

.services-sec {
    display: flex;
    max-width: 1000px;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    gap: 2rem;
}

.service-item {
    text-align: left;
    max-width: 50%;
}

.skills-main {
    padding: 50px 0;
    margin: 0 auto;
}

.skill {
    border-radius: 2px;
    background-color: rgb(29, 30, 41);
    color: var(--font-two);
    padding: 5px 10px;
    font-family: var(--font-code);
    margin: 5px;
}

.skills-title {
    text-align: center;
}

.skill-divide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill-div {
    text-align: center;
}

.skills-container {
    margin: 0 auto;
    max-width: 60%;
}


button {
    background-color: rgb(24, 36, 42);
    text-decoration: none;
    color: white;
    font-size: 18px;
    padding: 10px 20px;
    border: 2px solid rgb(173, 0, 107);
    border-radius: 5px;
    box-shadow: 5px 0px 6px rgba(255, 0, 157, 0.3);
    /* Add drop shadow */
}



button:hover {
    color: var(--font-two);

}

button:visited {
    color: var(--font-one);

}

.repo-main {
    text-align: center;
}

.repo-container {
    margin: 0 auto;
    /* Centers the container horizontally */
    padding-bottom: 50px;
    max-width: 1000px;
    /* Adjust to your desired width (100% max width or set a fixed value) */
    width: 90%;
    /* Adjusts the container to be 90% of the page width or any desired value */
    text-align: left;
    /* Aligns the text to the left inside the container */
}

.repo-img img {
    height: auto;
    /* Maintain aspect ratio */
    max-width: 600px;
    /* Allow resizing for smaller screens */
    border: 2px solid rgb(173, 0, 107);
    border-radius: 10px;
    box-shadow: 5px 0px 6px rgba(255, 0, 157, 0.3);
    /* Drop shadow */
}

.repo-item {
    display: flex;
    gap: 2rem;
    padding-bottom: 50px;
    width: 100%;
    /* Make sure the container takes full width */
    max-width: 1000px;
    /* Limit the width for large screens */
    flex-wrap: nowrap;
    /* Prevent wrapping, keep them side by side */
    align-items: center;
}

.repo-info {
    align-items: center;
}



/* Add media queries for better responsiveness on smaller screens */
@media (max-width: 768px) {
    .repo-item {
        flex-direction: column;
        /* Stack the image and text vertically */
        align-items: center;
        /* Center align the items */
    }

    .repo-img img {
        max-width: 100%;
        /* Ensure the image takes up the full width on smaller screens */
        height: auto;
        /* Maintain aspect ratio */
        max-height: 300px;
        /* Ensure the image height stays consistent */
    }

    .repo-item .repo-img,
    .repo-item .repo-text {
        flex: 1 1 100%;
        /* Allow the items to take up full width on small screens */

    }

    .about {
        color: white;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .services-sec {
        margin: 0 auto !important;
        padding: 15px 30px;
    }
}

@media (max-width: 480px) {
    .repo-container {
        padding: 20px;
    }

    .repo-item {
        padding-bottom: 50px;

    }

    .about {
        color: white;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        display: block !important;

    }

    .about-picture {
        display: flex;
        justify-content: center;
    }

    .services-sec {
        display: block;
        margin: 0 auto;

    }
    .service-item{
        max-width: 100%;
    }
}



.about {
    color: white;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 50px;

}



.about p {
    padding: 0;
    margin: 0;
    font-size: 25px;
    color: rgb(238, 236, 236);
}

.about-text {
    padding: 20px 50px;
    max-width: 500px;
}

.about img {
    max-width: 300px;
    border: 2px solid rgb(173, 0, 107);
    box-shadow: 15px 10px 0px rgba(255, 0, 157, 0.7);
    /* Drop shadow */

}

.text h2 {
    font-size: 18px;
    font-family: var(--font-code);
}

.text p {
    font-size: 16px;
}


.text h1 {
    color: var(--font-yellow);
    font-size: 50px;
    margin-bottom: -15px;
}

.contact {
    display: flex;
    width: 100px;
    color: white;
}

.contact-item {
    padding-right: 10px;
    width: 25px;
    color: white;
}

.contact-item a {
    color: white;
}



.footer {
    display: flex;
    height: auto;
    min-height: 70px;
    text-align: center;
    justify-content: space-between;
    font-family: var(--font-code);
    color: white;
    max-width: 60%;
    margin: 0 auto;
    padding-top: 50px;
}

.title-footer {
    font-size: 14px;
}


.exp-item {
    margin-bottom: 50px;
    background-color: rgb(230, 230, 230);
    border: solid 1px rgb(206, 206, 206);
    padding: 10px 20px;
    max-width: 60%;
    border-radius: 3px;

}

.exp-container {
    display: flex;
    justify-content: space-between;
}